.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

 @font-face { font-family: BigJonPro; src: url('fonts/BigJohnPRO-Regular.otf'); }
 @font-face { font-family: BigJohnProBold; src: url('fonts/BigJohnPRO-Bold.otf'); } 
      

.rowDiv {
  position:relative;
}

.infoDiv {
  position: absolute;
  top: 0px;
  left: 0px;
  
}

.infoTable {
  width: 100%;
}

ul {

}


.row span {
  background-color: #FAFAFA;

}

.row {

      filter: drop-shadow(0px 4px 4px rgba(244,247,93,0.25) );
    list-style: none;
    font-family : BigJohnProBold;
    margin-top: 10px;

}

.row span  {

 display: inline-block; 
  font-size: 16px;
  height: 56px;
  margin-bottom: 5px;
   
}

.rank img {




}

.list-align {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0;
}

.rank {
  width: 100px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 30px;
}

.ranktitle {
  width: 100px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 30px;
}

.link {
  width: 170px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.buy {
  width: 110px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.buytitle {
  width: 110px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.profit {
  width: 140px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.avgprice {
  width: 150px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.avgpricetitle {
  width: 150px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.sold {
  width: 140px;
    padding-top: 10px;
  padding-bottom: 10px;
}

.soldtitle {
  width: 140px;
    padding-top: 10px;
  padding-bottom: 10px;
}

.genesis {
  width: 150px;
   padding-top: 10px;
  padding-bottom: 10px;
  }
  
  
.genesistitle {
  width: 150px;
   padding-top: 10px;
  padding-bottom: 10px;
  }
  
.follow {
  width: 90px;
   padding-top: 10px;
  padding-bottom: 10px;
  vertical-align: top;
  }
  
    
.followtitle {
  width: 90px;
   padding-top: 10px;
  padding-bottom: 10px;
  vertical-align: top;
  }
  
.followimg {
    position: relative;
    bottom: 12px;
    right: 15px;
    padding-right:10px;
  }

.roi {
  width: 110px;
   padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 30px;
    text-align: right;
}

.roititle {
  width: 110px;
   padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 30px;
    text-align: right;
}


.follow {
  width: 110px;
   padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 30px;
    text-align: right;
}

.followtitle {
  width: 110px;
   padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 30px;
    text-align: right;
}


.row a {
  color: #000;
  text-decoration: none;
}

.contentDiv {
  position: relative;
  top: 15px;
}

.title span {
  background-color: #fff;
}
.title {
  filter: none;
  height: 50px;

}

.title img {


}

.imgspan {
  background-image: url('./images/1st.png');
  background-repeat: no-repeat;
  height: 35px;
  background-position-x: 40px;
}

.imgspan2 {
  background-image: url('./images/2nd.png');
  background-repeat: no-repeat;
  height: 35px;
  background-position-x: 40px;
}

.imgspan3 {
  background-image: url('./images/3rd.png');
  background-repeat: no-repeat;
  height: 35px;
  background-position-x: 40px;
}

.title .contentDiv {
  text-align: center;
}

.contentDiv {

}

#topleft {
  padding-right: 350px;
}

#toprow {
  list-style: none;
}



#eth,#solana,#polygon {
 padding-right: 30px;
}

#footer {
  padding: 10px;

}
