.alert-enter {
  opacity: 0;

}
.alert-enter-active {
  opacity: 1;

  transition: opacity 2000ms, transform 100ms;
  transition-timing-function: ease-in;



}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;

  transition: opacity 2000ms, transform 100ms;
  transition-timing-function: ease-out;


}

